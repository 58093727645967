@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/open-sans/400.css";
@import "@fontsource/open-sans/400-italic.css";
@import "@fontsource/open-sans/600.css";
@import "@fontsource/open-sans/600-italic.css";
@import "@fontsource/open-sans/700.css";
@import "@fontsource/open-sans/700-italic.css";

@layer components {
  a,
  button {
    /* reset button styles */
    @apply appearance-none focus:outline-none ring-yellow-400 focus-visible:ring-2;
  }

  .prose h1 {
    @apply text-xl font-bold mb-2 text-primary-900;
  }

  .prose h2 {
    @apply text-lg font-bold mb-2 text-primary-900;
  }

  .prose h3 {
    @apply text-base font-bold mb-2 text-primary-900;
  }

  .prose h4 {
    @apply text-base font-bold mb-2;
  }

  .prose a {
    @apply text-secondary-800 underline;
  }
  .prose p {
    @apply mb-4 last:mb-0;
  }
  .prose strong {
    @apply text-gray-600 font-bold;
  }

  .prose ul {
    @apply list-disc list-inside mb-2;
  }

  .prose ol {
    @apply list-decimal list-inside mb-4;
  }

  .chart text {
    @apply font-sans fill-gray-600;
  }
}
